"use client";
import React, { useState } from "react";
import {
  motion,
  AnimatePresence,
  useScroll,
  useMotionValueEvent,
} from "framer-motion";
import { cn } from "@/lib/utils";
import Link from "next/link";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./select";
import { usePathname, useRouter } from "@/navigation";
import { useParams } from "next/navigation";

export const FloatingNav = ({
  navItems,
  className,
}: {
  navItems: {
    name: string;
    link: string;
    icon?: JSX.Element;
  }[];
  className?: string;
}) => {
  const { scrollYProgress } = useScroll();
  const [visible, setVisible] = useState(true);
  const { locale } = useParams();
  const router = useRouter();
  const pathname = usePathname() as "/";

  useMotionValueEvent(scrollYProgress, "change", (current) => {
    // Check if current is not undefined and is a number
    if (typeof current === "number") {
      const direction = current! - scrollYProgress.getPrevious()!;
      setVisible(direction < 0);
    }
  });

  const changeLocale = (newLocale: string) => {
    router.push(pathname, { locale: newLocale as "en" });
  };

  const selectClassName = "w-[60px] overflow-hidden text-sm text-foreground";

  return (
    <AnimatePresence mode="wait">
      <motion.div
        initial={{
          opacity: 1,
          y: -100,
        }}
        animate={{
          y: visible ? 0 : -100,
          opacity: visible ? 1 : 0,
        }}
        transition={{
          duration: 0.2,
        }}
        className={cn(
          "flex md:max-w-fit  fixed top-0 md:top-10 inset-x-0 mx-auto border border-transparent dark:border-white/[0.2] rounded-b-2xl md:rounded-full dark:bg-black bg-white shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] z-[5000] px-2 pl-4 py-2  items-center justify-between space-x-12",
          className,
        )}
      >
        <nav className="flex gap-4">
          <div className="relative mr-4">
            <Select
              onValueChange={(value) => {
                if (locale !== value) changeLocale(value);
              }}
            >
              <SelectTrigger className={selectClassName}>
                <SelectValue
                  className={selectClassName}
                  placeholder={((locale as string) || "en").toUpperCase()}
                />
              </SelectTrigger>
              <SelectContent className={selectClassName}>
                <SelectItem
                  className={selectClassName}
                  defaultChecked={locale === "en"}
                  value="en"
                >
                  {"EN"}
                </SelectItem>
                <SelectItem
                  className={selectClassName}
                  defaultChecked={locale === "es"}
                  value="es"
                >
                  {"ES"}
                </SelectItem>
                <SelectItem
                  className={selectClassName}
                  defaultChecked={locale === "it"}
                  value="it"
                >
                  {"IT"}
                </SelectItem>
                <SelectItem
                  className={selectClassName}
                  defaultChecked={locale === "ro"}
                  value="ro"
                >
                  {"RO"}
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
          {navItems.map((navItem, idx) => (
            <Link
              key={`link=${idx}`}
              href={navItem.link}
              className={cn(
                "relative dark:text-neutral-50 items-center flex space-x-1 text-neutral-600 dark:hover:text-neutral-300 hover:text-neutral-500",
              )}
            >
              <span className="block sm:hidden">{navItem.icon}</span>
              <span className="hidden text-sm sm:block">{navItem.name}</span>
            </Link>
          ))}
        </nav>
        <button className="relative rounded-full border border-neutral-200 px-4 py-2 text-sm font-medium text-black dark:border-white/[0.2] dark:text-white">
          <span>{"$ign In"}</span>
          <span className="absolute inset-x-0 -bottom-px mx-auto h-px w-1/2 bg-gradient-to-r from-transparent via-blue-500  to-transparent" />
        </button>
      </motion.div>
    </AnimatePresence>
  );
};
