"use client";
import React from "react";
import { IconHome, IconMessage, IconUser } from "@tabler/icons-react";
import { FloatingNav } from "@/components/ui/floating-navbar";

const navItems = [
  {
    name: "Home",
    link: "/",
    icon: <IconHome className="size-4 text-neutral-500 dark:text-white" />,
  },
  {
    name: "About",
    link: "/about",
    icon: <IconUser className="size-4 text-neutral-500 dark:text-white" />,
  },
  {
    name: "Contact",
    link: "/contact",
    icon: <IconMessage className="size-4 text-neutral-500 dark:text-white" />,
  },
];

export default function Header() {
  return <FloatingNav navItems={navItems} />;
}
